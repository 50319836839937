import "./nav.css"
import {useNavigate} from "react-router-dom"

const Nav = () => {
    const navigate = useNavigate();
    const handleClickHome = () => {
        navigate('/')
    }
    const handleClickPrivacy = () => {
        navigate('/privacy')
    }
    return (
        <header>
            <h1 className="title" onClick={handleClickHome}>Генератор<i style={{color: "#5CC3FD"}}>_</i>сметы</h1>
          <div className="nav">
            <div className="nav-item home" onClick={handleClickHome}>Домой</div>
            <div className="nav-item" onClick={handleClickPrivacy}>Конфиденциальность</div>
          </div>
        
        </header>
    )
}
export default Nav